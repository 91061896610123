<script setup lang="ts">
import useMainNavigation from '~/data/mainNavigation'

const authStore = useAuthStore()
const garageStore = useGarageStore()

const userId = computed(() => authStore.currentUser?.id)

const mainNavigation = userId.value
  ? useMainNavigation(userId.value.toString(), garageStore)
  : ref([])
</script>

<template>
  <div data-testid="navigation-main-menu" class="tw-flex tw-flex-col">
    <NuxtLink
      v-for="navItem in mainNavigation"
      :key="navItem.translationKey"
      :to="navItem.route"
      class="tw-flex tw-items-center tw-gap-3 tw-py-1"
    >
      <div class="icon tw-inline-flex tw-items-center tw-justify-center">
        <FontAwesomeIcon class="tw-h-4 tw-w-4 tw-text-gray-800" :icon="navItem.fontAwesomeIcon" />
      </div>

      <span class="tw-truncate tw-text-sm tw-leading-none">{{ $t(navItem.translationKey) }}</span>

      <div
        v-if="navItem.count"
        class="tw-ml-auto tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-riser-red tw-text-xs tw-text-white"
      >
        {{ navItem.count < 10 ? navItem.count : '!' }}
      </div>
    </NuxtLink>
  </div>
</template>
